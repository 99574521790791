import Script from 'next/script';

export default function MinifiedFunraiseScript() {
    return (
        <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `(function(f,u,n,r,a,i,s,e){var data={window:window,document:document,tag:"script",data:"funraise",orgId:f,uri:u,common:n,client:r,script:a};var scripts;var funraiseScript;data.window[data.data]=data.window[data.data]||[];if(data.window[data.data].scriptIsLoading||data.window[data.data].scriptIsLoaded)return;data.window[data.data].loading=true;data.window[data.data].push("init",data);scripts=data.document.getElementsByTagName(data.tag)[0];funraiseScript=data.document.createElement(data.tag);funraiseScript.async=true;funraiseScript.src=data.uri+data.common+data.script+"?orgId="+data.orgId;scripts.parentNode.insertBefore(funraiseScript,scripts)})('e5638293-bcca-4745-9574-d09856c24860','https://assets.funraise.io','/widget/common/2.0','/widget/client','/inject-form.js')`
            }}
        />
    );
}
