import Script from 'next/script';
import MinifiedFunraiseScript from './MinifiedFunraiseScript';

export default function ButtonLauncher({ formId, testDonation }) {
    const testMode = testDonation ? `window.funraise.push('config', { form: 38638 }, { mode: 'test' });` : '';

    return (
        <>
            <MinifiedFunraiseScript />

            <Script
                id="funraise-redirect"
                dangerouslySetInnerHTML={{
                    __html: `                        
                        function giveRedirect(donor, donation) {
                            if(donation.paymentType !== 'bitcoin') {
                                window.location.href = 'https://jewsforjesus.org/thank-you';
                            }
                        }  
                        `
                }}
            />
            <Script
                id="funraise-push"
                dangerouslySetInnerHTML={{
                    __html: `  
                        window.funraise.push('create', { form: ${formId}})
                        window.funraise.push('onSuccess', { form: ${formId} }, giveRedirect)
                        window.funraise.push('config', { form: ${formId} }, {
                            i18n: {
                                phrases: {
                                    donor_information_email_opt_in: {
                                        'en': 'Send me newsletter and partnering emails!',
                                    }
                                }
                            }
                        }) 
                        ${testMode}
                    `
                }}
            />
        </>
    );
}
